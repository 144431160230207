import * as React from "react"
import { graphql } from 'gatsby'

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import { default as Card} from 'components/GridCard';

import DataProvider from 'providers/data';

const SolutionListing = props => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	const { edges: solutionEdges } = props.data.allSustainableSolution;
	let solutions = solutionEdges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!solutions !== false) && props.providerStatusText === 'LOADED') {
		solutions = props.providerResponse.data;
	}
	if (page) page.gridSize = 12;

	return (
		<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
			<Content gridSize={page ? 12 : 8} {...page} />
			{!!solutions?.length && solutions.map((card, index) => (
				<Card description={card.body} {...card} columnSize="6" key={`solution-${index}`} order={index+1} />
			))}
		</Layout>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="SustainableSolution" apiParams={{_sort: 'sort'}}><SolutionListing {...props} /></DataProvider>
export default getData;

export const query = graphql`
{
	allContentPage(
	  filter: {uri: {eq: "/sustainable-solutions/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  showContactForm
		  showNewsletterSignUp
		  solutionBody
		  solutionLink
		  solutionLinkText
		  solutionTitle
		  solutionImage
		  solutions
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  hoverboards
		}
	  }
	}
	allSustainableSolution(sort: {fields: sort}) {
	  edges {
		node {
		  icon
		  title
		  body
		  link
		  linkText
		  image
		}
	  }
	}
}
`